import IEntry from "../models/IBlogPost";
import networkService from "./NetworkService";

class BlogService {

    /**
     * @description Gets all blogs
     */
    async getBlogs(): Promise<IEntry[]> {
        const result = await networkService.get<IEntry[]>(`/blog/entries`);

        if (result.isSuccessful) {
            return result.data as IEntry[];
        }

        throw result.data;
    }

    /**
     * @description Gets the specified blog
     * @param {string} id The ID of the blog to be returned
     */
    async getBlog(id: string): Promise<IEntry> {
        const result = await networkService.get<IEntry>(`/blog/entry/${id}`);

        if (result.isSuccessful) {
            return result.data as IEntry;
        }

        throw result.data;
    }

    /**
     * @description Creates a new blog
     * @param {IEntry} blog The blog data to be created
     */
    async createBlog(blog: IEntry): Promise<IEntry> {
        const result = await networkService.put<IEntry, IEntry>(`/blog/entry`, blog);

        if (result.isSuccessful) {
            return result.data as IEntry;
        }

        throw result.data;
    }

    /**
     * @description Updates the specified blog
     * @param {Partial<IEntry>} blog The partial blog data to be updated
     */
    async updateBlog(blog: Partial<IEntry>): Promise<IEntry> {
        const result = await networkService.patch<Partial<IEntry>, IEntry>(`/blog/entry/`, blog);

        if (result.isSuccessful) {
            return result.data as IEntry;
        }

        throw result.data;
    }

    /**
     * @description Deletes the specified blog
     * @param {string} id The ID of the blog to be deleted
     */
    async deleteBlog(id: string): Promise<void> {
        const result = await networkService.delete<void>(`/blog/entry/${id}`);

        if (result.isSuccessful) {
            return;
        }

        throw result.data;
    }

    /**
     * @description Gets all blog categories
     */
    async getCategories(): Promise<string[]> {
        const result = await networkService.get<string[]>(`/blog/categories`);

        if (result.isSuccessful) {
            return result.data as string[];
        }

        throw result.data;
    }
}

const blogService = new BlogService();
export default blogService;