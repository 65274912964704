import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../home/Home";
import Blogs from "../administration/blogs/Blogs";
import AdminLayout from "../components/admin/layout/AdminLayout";
import Partners from "../administration/partners/Partners";
import Testimonials from "../administration/testimonials/Testimonials";
import EditBlog from "../administration/blogs/EditBlog";
import EditTestimonial from "../administration/testimonials/EditTestimonial";
import Awards from "../administration/portfolios/awards/Awards";
import EditAward from "../administration/portfolios/awards/EditAward";
import CaseStudies from "../administration/portfolios/casestudies/CaseStudies";
import EditCaseStudy from "../administration/portfolios/casestudies/EditCaseStudy";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/administration/*" element={<AdminLayout />}>
                    <Route path="blogs" element={<Blogs />} />
                    <Route path="editblog/:id" element={<EditBlog />} />
                    <Route path="editblog" element={<EditBlog />} /> {/* For creating new blogs */}
                    <Route path="casestudies" element={<CaseStudies />} />
                    <Route path="editcasestudy/:id" element={<EditCaseStudy />} />
                    <Route path="editcasestudy" element={<EditCaseStudy />} /> {/* For creating new portfolios */}
                    <Route path="partners" element={<Partners />} />
                    <Route path="testimonials" element={<Testimonials />} />
                    <Route path="edittestimonial:/id" element={<EditTestimonial />} />
                    <Route path="edittestimonial" element={<EditTestimonial />} /> {/* For creating new testimonials */}
                    <Route path="awards" element={<Awards />} />
                    <Route path="editaward:/id" element={<EditAward />} />
                    <Route path="editaward" element={<EditAward />} /> {/* For creating new testimonials */}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Router;