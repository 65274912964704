import IPartner from "../models/IPartner";
import networkService from "./NetworkService";

class PartnerService {

    /**
     * @description Gets all partners
     */
    async getPartners(): Promise<IPartner[]> {
        const result = await networkService.get<IPartner[]>(`/partners`);

        if (result.isSuccessful) {
            return result.data as IPartner[];
        }

        throw result.data;
    }

    /**
     * @description Gets the specified partner
     * @param {number} id The ID of the partner to be returned
     */
    async getPartner(id: number): Promise<IPartner> {
        const result = await networkService.get<IPartner>(`/partner/${id}`);

        if (result.isSuccessful) {
            return result.data as IPartner;
        }

        throw result.data;
    }

}

const partnerService = new PartnerService();
export default partnerService;
