import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import portfolioService from '../../../services/PortfolioService';
import IAward from '../../../models/IAward';
import Table from '../../Table';

const Awards: React.FC = () => {
    const [awards, setAwards] = useState<IAward[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAwards = async () => {
            try {
                const response: IAward[] = await portfolioService.getAwards();
                setAwards(response);
            } catch (error) {
                console.error('Error fetching awards:', error);
            }
        };

        fetchAwards();
    }, []);

    const handleEditAward = (id: string) => {
        navigate(`/administration/editaward/${id}`);
    };

    const handleAddAward = () => {
        navigate('/administration/editaward');
    };

    const handleDeleteAward = (id: string) => {
        // Implement delete logic here
        console.log(`Delete award with id: ${id}`);
    };

    return (
        <Table
            title="Awards"
            data={awards}
            handleAdd={handleAddAward}
            handleEdit={handleEditAward}
            handleDelete={handleDeleteAward}
        />
    );
};

export default Awards;