import axios from "axios";
import IErrorResponse from "../models/IErrorResponse";
import INetworkResponse from "../models/INetworkResponse";
import Cookies from "js-cookie";

class NetworkService {

    private api: any;

    constructor() {
        const baseUrl = process.env.REACT_APP_API_BASE_URL || "/api/";
        this.api = axios.create({
            baseURL: baseUrl,
            timeout: 30_000
        });

        // Intercept requests and add auth token to headers
        this.api.interceptors.request.use(async (config: any) => {
            const token = Cookies.get('token');
            if (token) {
                config.headers["Authorization"] = `Basic ${token}`;
            }

            return config;
        }, (error: any) => Promise.reject(error));
    }

    /**
     * @description Downloads a file from the specified path and returns a promise that resolves to the file contents.
     * @param {string} path The path to download the file from.
     * @returns A promise that resolves to the file contents.
     */
    public async download(path: string): Promise<Blob> {
        try {
            const response = await this.api.get(path, { responseType: "blob" });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                console.log(error.message)
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a GET request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the GET request to.
     * @returns A promise that resolves to the network response.
     */
    public async get<T>(path: string): Promise<INetworkResponse<T>> {
        try {
            const response = await this.api.get(path);
            return this.createNetworkResponse<T>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a POST request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the POST request to.
     * @param {{}} body The body of the request.
     * @returns A promise that resolves to the network response.
     */
    public async post<TBody, TResponse>(path: string, body?: TBody): Promise<INetworkResponse<TResponse>> {
        let contentType: string;
        
        if (typeof body === "string") {
            contentType = "text/plain";
        } else if (body instanceof FormData) {
            contentType = "multipart/form-data";
        } else {
            contentType = "application/json";
        }
        
        try {
            const response = await this.api.post(path, body, { headers: { "Content-Type": contentType } });

            return this.createNetworkResponse<TResponse>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a PUT request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the PUT request to.
     * @param {{}} body The body of the request.
     * @returns A promise that resolves to the network response.
     */
    public async put<TBody, TResponse>(path: string, body?: TBody): Promise<INetworkResponse<TResponse>> {
        let contentType: string;
        
        if (typeof body === "string") {
            contentType = "text/plain";
        } else if (body instanceof FormData) {
            contentType = "multipart/form-data";
        } else {
            contentType = "application/json";
        }
        
        try {
            const response = await this.api.put(path, body, { headers: { "Content-Type": contentType } });
            return this.createNetworkResponse<TResponse>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a PATCH request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the PATCH request to.
     * @param {{}} body The body of the request.
     * @returns A promise that resolves to the network response.
     */
    public async patch<TBody, TResponse>(path: string, body?: TBody): Promise<INetworkResponse<TResponse>> {
        try {
            const response = await this.api.patch(path, body);
            return this.createNetworkResponse<TResponse>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    /**
     * Sends a DELETE request to the specified path and returns a promise that resolves to the network response.
     * @param {string} path The path to send the DELETE request to.
     * @returns A promise that resolves to the network response.
     */
    public async delete<T>(path: string): Promise<INetworkResponse<T>> {
        try {
            const response = await this.api.delete(path);
            return this.createNetworkResponse<T>(response);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw error.response;
            } else {
                throw error;
            }
        }
    }

    private isRequestSuccessful = (status: number) => status >= 200 && status < 300;

    private createNetworkResponse = <T>(response: any): INetworkResponse<T> => {
        const networkResponse: INetworkResponse<T> = {
            status: response.status,
            isSuccessful: this.isRequestSuccessful(response.status)
        };

        if (!response.data && response.data !== 0) {
            return networkResponse;
        }

        if (networkResponse.isSuccessful) {
            networkResponse.data = response.data as T;
        } else {
            networkResponse.data = response.data as IErrorResponse;
        }

        return networkResponse;
    }
}

const networkService = new NetworkService();
export default networkService;