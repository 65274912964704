import React from 'react';
import styles from './Table.module.scss'; // Assuming the CSS file is named Table.module.css
import { Button } from "@fluentui/react-components";
import ITitle from '../models/ITitle';

// Define the TableProps interface for any type of data
interface TableProps<T> {
  title: string;
  data: T[];
  handleAdd: () => void;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
}

const getDisplayValue = (item: { title?: string | ITitle, name?: string, clientName?: string }) => {
  if (typeof item.title === 'object' && item.title !== null) {
    return item.title.title || item.title.pageTitle || 'N/A';
  }
  return item.title || item.name || item.clientName || 'N/A';
};

// Reusable Table component with fixed "Title" and "Actions" columns
const Table = <T extends { id: string | number; title?: string | ITitle, name?: string, clientName?: string }>({
  title,
  data,
  handleAdd,
  handleEdit,
  handleDelete
}: TableProps<T>) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <Button className={styles.addButton} appearance="primary" onClick={handleAdd}>
          Add New
        </Button>
      </div>

      <table className={styles.dataTable}>
        <thead>
          <tr>
            <th>Title</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{getDisplayValue(item)}</td>
              <td>
                <Button
                  className={styles.editButton}
                  appearance="secondary"
                  onClick={() => handleEdit(item.id.toString())}
                >
                  Edit
                </Button>

                <Button
                  className={styles.deleteButton}
                  appearance="secondary"
                  onClick={() => handleDelete(item.id.toString())}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;