import ICaseStudy from "../models/ICaseStudy";
import IAward from "../models/IAward";
import networkService from "./NetworkService";

class PortfolioService {

    /**
     * @description Gets all case studies
     */
    async getCaseStudies(): Promise<ICaseStudy[]> {
        const result = await networkService.get<ICaseStudy[]>(`/portfolio/case-studies`);

        if (result.isSuccessful) {
            return result.data as ICaseStudy[];
        }

        throw result.data;
    }

    /**
     * @description Gets the specified case study
     * @param {string} id The ID of the case study to be returned
     */
    async getCaseStudy(id: string): Promise<ICaseStudy> {
        const result = await networkService.get<ICaseStudy>(`/portfolio/case-study/${id}`);

        if (result.isSuccessful) {
            return result.data as ICaseStudy;
        }

        throw result.data;
    }

    /**
     * @description Creates a new case study
     * @param {ICaseStudy} caseStudy The case study data to be created
     */
    async createCaseStudy(caseStudy: ICaseStudy): Promise<ICaseStudy> {
        const result = await networkService.put<ICaseStudy, ICaseStudy>(`/portfolio/case-study`, caseStudy);

        if (result.isSuccessful) {
            return result.data as ICaseStudy;
        }

        throw result.data;
    }

    /**
     * @description Partially updates the specified case study
     * @param {string} id The ID of the case study to be partially updated
     * @param {Partial<ICaseStudy>} caseStudy The partial case study data to be updated
     */
    async updateCaseStudy(id: string, caseStudy: Partial<ICaseStudy>): Promise<ICaseStudy> {
        const result = await networkService.patch<Partial<ICaseStudy>, ICaseStudy>(`/portfolio/case-study/${id}`, caseStudy);

        if (result.isSuccessful) {
            return result.data as ICaseStudy;
        }

        throw result.data;
    }

    /**
     * @description Deletes the specified case study
     * @param {string} id The ID of the case study to be deleted
     */
    async deleteCaseStudy(id: string): Promise<void> {
        const result = await networkService.delete<void>(`/portfolio/case-study/${id}`);

        if (result.isSuccessful) {
            return;
        }

        throw result.data;
    }

    /**
     * @description Gets all case study categories
     */
    async getCategories(): Promise<string[]> {
        const result = await networkService.get<string[]>(`/portfolio/case-studies/categories`);

        if (result.isSuccessful) {
            return result.data as string[];
        }

        throw result.data;
    }

    /**
     * @description Gets all awards
     */
    async getAwards(): Promise<IAward[]> {
        const result = await networkService.get<IAward[]>(`/portfolio/awards`);

        if (result.isSuccessful) {
            return result.data as IAward[];
        }

        throw result.data;
    }

    /**
     * @description Gets the specified award
     * @param {string} id The ID of the award to be returned
     */
    async getAward(id: string): Promise<IAward> {
        const result = await networkService.get<IAward>(`/portfolio/awards/${id}`);

        if (result.isSuccessful) {
            return result.data as IAward;
        }

        throw result.data;
    }

    /**
     * @description Creates a new award
     * @param {IAward} award The award data to be created
     */
    async createAward(award: IAward): Promise<IAward> {
        const result = await networkService.put<IAward, IAward>(`/portfolio/awards`, award);

        if (result.isSuccessful) {
            return result.data as IAward;
        }

        throw result.data;
    }

    /**
     * @description Partially updates the specified award
     * @param {string} id The ID of the award to be partially updated
     * @param {Partial<IAward>} award The partial award data to be updated
     */
    async updateAward(id: string, award: Partial<IAward>): Promise<IAward> {
        const result = await networkService.patch<Partial<IAward>, IAward>(`/portfolio/awards/${id}`, award);

        if (result.isSuccessful) {
            return result.data as IAward;
        }

        throw result.data;
    }

    /**
     * @description Deletes the specified award
     * @param {string} id The ID of the award to be deleted
     */
    async deleteAward(id: string): Promise<void> {
        const result = await networkService.delete<void>(`/portfolio/award/${id}`);

        if (result.isSuccessful) {
            return;
        }

        throw result.data;
    }
}

const portfolioService = new PortfolioService();
export default portfolioService;