import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Textarea, Field, Combobox, Option, ComboboxProps } from '@fluentui/react-components';
import IEntry from '../../models/IBlogPost';
import blogService from '../../services/BlogService';
import imageService from '../../services/ImageService';
import styles from './EditBlog.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import IImage from '../../models/IImage';

const initialBlog: IEntry = {
    id: '',
    title: { pageTitle: '', title: '' },
    subtitle: '',
    author: '',
    published: '',
    updated: '',
    content: '',
    image: { url: '', altText: '' } as IImage,
    thumbnail: { url: '', altText: '' } as IImage,
    metaDescription: '',
    slug: '',
    readTime: '',
    tags: [],
    comments: [],
};

export default function EditBlog() {
    const { id } = useParams<{ id: string }>();
    const [blog, setBlog] = useState<IEntry>(initialBlog);
    const [categories, setCategories] = useState<string[]>([]);
    const [, setSelectedTags] = useState<string[]>([]);
    const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);  // State for the thumbnail preview

    useEffect(() => {
        blogService.getCategories().then(setCategories);
        if (id) {
            blogService.getBlog(id).then((data) => {
                setBlog(data);
                setSelectedTags(data.tags); // Sync tags with the fetched data
            });
        }
    }, [id]);

    useEffect(() => {
        // If blog already has a thumbnail URL, set the thumbnailPreview state
        if (blog.thumbnail?.url) {
            setThumbnailPreview(blog.thumbnail.url);
        }
    }, [blog.thumbnail]);

    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0]; // Returns 'YYYY-MM-DD'
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setBlog((prevBlog) => {
            if (name === "pageTitle" || name === "title") {
                return {
                    ...prevBlog,
                    title: {
                        ...prevBlog.title,
                        [name]: value,
                    },
                };
            }
            return {
                ...prevBlog,
                [name]: value,
            };
        });
    };

    const handleBodyChange = (value: string) => {
        setBlog((prevBlog) => ({ ...prevBlog, content: value }));
    };

    const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        setBlog((prevBlog) => {
            const updatedBlog = { ...prevBlog, tags: data.selectedOptions };
            return updatedBlog;
        });
    };

    // Inside your component
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            try {
                // Generate a thumbnail
                const createThumbnail = (imageFile: File): Promise<File> => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = URL.createObjectURL(imageFile);
                        img.onload = () => {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            if (!ctx) {
                                reject(new Error("Canvas context not available"));
                                return;
                            }
    
                            // Set the dimensions for the thumbnail
                            const thumbnailWidth = 150; // Adjust as needed
                            const thumbnailHeight = 150; // Adjust as needed
    
                            canvas.width = thumbnailWidth;
                            canvas.height = thumbnailHeight;
    
                            // Draw the resized image on the canvas
                            ctx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);
    
                            // Convert canvas to blob
                            canvas.toBlob(
                                (blob) => {
                                    if (!blob) {
                                        reject(new Error("Blob conversion failed"));
                                        return;
                                    }
                                    const thumbnailFile = new File([blob], `thumbnail-${file.name}`, {
                                        type: file.type,
                                    });
                                    resolve(thumbnailFile);
                                },
                                file.type,
                                0.9 // Adjust quality as needed
                            );
                        };
                        img.onerror = (err) => reject(err);
                    });
                };
    
                const thumbnailFile = await createThumbnail(file);
    
                // Upload the original image
                const originalImageUploadRequest = {
                    containerName: 'blog-uploads',
                    blobName: file.name,
                    url: URL.createObjectURL(file),
                };
                const originalImageUrl = await imageService.uploadImage(originalImageUploadRequest);
    
                // Upload the thumbnail image
                const thumbnailUploadRequest = {
                    containerName: 'blog-uploads',
                    blobName: thumbnailFile.name,
                    url: URL.createObjectURL(thumbnailFile),
                };
                const thumbnailUrl = await imageService.uploadImage(thumbnailUploadRequest);
    
                // Update the blog state
                setBlog((prevBlog) => ({
                    ...prevBlog,
                    image: { url: originalImageUrl, altText: prevBlog.title.title },
                    thumbnail: { url: thumbnailUrl, altText: prevBlog.title.title },
                }));

                setThumbnailPreview(thumbnailUrl);
    
                console.log('Images uploaded successfully');
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (id) {
            blogService.updateBlog(blog).then(() => {
                console.log('Blog updated successfully');
            });
        } else {
            blogService.createBlog(blog).then(() => {
                console.log('Blog created successfully');
            });
        }
    };

    const formatDateForInput = (isoDate: string) => {
        if (!isoDate) return ''; // Handle empty or undefined values
        return isoDate.split('T')[0]; // Extract only the date portion
    };

    return (
        <div className={styles.formContainer}>
            <h1>{id ? 'Edit Blog' : 'Add New Blog'}</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field label="Page Title" required>
                    <Input name="pageTitle" value={blog.title.pageTitle} onChange={handleChange} />
                </Field>
                <Field label="Title" required>
                    <Input name="title" value={blog.title.title} onChange={handleChange} />
                </Field>
                <Field label="Subtitle">
                    <Input name="subtitle" value={blog.subtitle || ''} onChange={handleChange} />
                </Field>

                <div className={styles.inlineFields}>
                    <Field label="Author">
                        <Input name="author" value={blog.author || ''} onChange={handleChange} />
                    </Field>
                    <Field label="Date Published" required>
                        <Input
                            type="date"
                            name="published"
                            value={formatDateForInput(blog.published) || getTodayDate()} // Use today's date if no value
                            onChange={handleChange}
                        />
                    </Field>
                    <Field label="Date Updated">
                        <Input
                            type="date"
                            name="updated"
                            value={formatDateForInput(blog.updated) || getTodayDate()} // Format the date
                            onChange={handleChange}
                        />
                    </Field>
                </div>
                <Field label="Body" required>
                    <ReactQuill
                        theme="snow"
                        value={blog.content}
                        onChange={handleBodyChange}
                        style={{ height: '300px', marginBottom: '40px' }}
                    />
                </Field>
                <Field label="Meta Description" required>
                    <Textarea name="metaDescription" value={blog.metaDescription} onChange={handleChange} />
                </Field>
                <Field label="Upload Image">
                    <Button appearance="outline" onClick={handleFileButtonClick}>
                        Upload Image
                    </Button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageUpload}
                    />
                </Field>
                {thumbnailPreview && (
                <div className={styles.thumbnailPreview}>
                    <img src={thumbnailPreview} alt={blog.thumbnail.altText} width={150} height={150} />
                </div>
            )}
                <div className={styles.inlineFields}>
                    <Field label="Slug" required>
                        <Input name="slug" value={blog.slug} onChange={handleChange} />
                    </Field>
                    <Field label="Reading Time (minutes)" required>
                        <Input
                            type="number"
                            name="readTime"
                            value={blog.readTime}
                            onChange={handleChange}
                        />
                    </Field>
                </div>
                <Field label="Categories" required>
                    <Combobox
                        multiselect={true}
                        placeholder="Select one or more categories"
                        value={blog.tags.join(", ")} // Display selected tags as comma-separated
                        onBlur={() => {
                            // Ensure selected tags remain displayed on blur
                            const displayValue = blog.tags.join(", ");
                            setBlog((prevBlog) => ({ ...prevBlog, displayTags: displayValue }));
                        }}
                        onOptionSelect={onSelect}
                    >
                        {categories.map((category) => (
                            <Option key={category} value={category}>
                                {category}
                            </Option>
                        ))}
                    </Combobox>
                </Field>
                <Button type="submit" appearance="primary" className={styles.button}>
                    Save
                </Button>
            </form>
        </div>
    );
}