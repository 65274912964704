import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Textarea, Field } from '@fluentui/react-components';
import IAward from '../../../models/IAward';
import portfolioService from '../../../services/PortfolioService';
import imageService from '../../../services/ImageService';
import styles from './EditAward.module.scss';
import IImage from '../../../models/IImage';

const initialAward: IAward = {
    id: '',
    name: '',
    image: { url: '', altText: '' } as IImage,
    description: '',
};

export default function EditAward() {
    const { id } = useParams<{ id: string }>();
    const [award, setAward] = useState<IAward>(initialAward);
    const [imagePreview, setImagePreview] = useState<string | null>(null);  // State for the image preview

    useEffect(() => {
        if (id) {
            portfolioService.getAward(id).then(data => {
                setAward(data);
            });
        }
    }, [id]);

    useEffect(() => {
        // If award already has an image URL, set the imagePreview state
        if (award.image.url) {
            setImagePreview(award.image.url);
        }
    }, [award.image.url]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setAward((prevAward) => ({
            ...prevAward,
            [name]: value,
        }));
    };

    // Inside your component
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            try {
                // Upload the image
                const imageUploadRequest = {
                    containerName: 'award-images',
                    blobName: file.name,
                    url: URL.createObjectURL(file),
                };
                const imageUrl = await imageService.uploadImage(imageUploadRequest);
    
                // Update the award state
                setAward((prevAward) => ({
                    ...prevAward,
                    image: { url: imageUrl, altText: prevAward.name },
                }));

                setImagePreview(imageUrl);
    
                console.log('Image uploaded successfully');
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (id) {
            portfolioService.updateAward(id, award).then(() => {
                console.log('Award updated successfully');
            });
        } else {
            portfolioService.createAward(award).then(() => {
                console.log('Award created successfully');
            });
        }
    };

    return (
        <div className={styles.formContainer}>
            <h1>{id ? 'Edit Award' : 'Add New Award'}</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field label="Name" required>
                    <Input name="name" value={award.name} onChange={handleChange} />
                </Field>
                <Field label="Description" required>
                    <Textarea name="description" value={award.description} onChange={handleChange} />
                </Field>
                <Field label="Upload Image">
                    <Button appearance="outline" onClick={handleFileButtonClick}>
                        Upload Image
                    </Button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageUpload}
                    />
                </Field>
                {imagePreview && (
                    <div className={styles.imagePreview}>
                        <img src={imagePreview} alt={award.image.url} width={150} height={150} />
                    </div>
                )}
                <Button type="submit" appearance="primary" className={styles.button}>
                    Save
                </Button>
            </form>
        </div>
    );
}