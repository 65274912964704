import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import portfolioService from '../../../services/PortfolioService';
import ICaseStudy from '../../../models/ICaseStudy';
import Table from '../../Table';

const CaseStudies: React.FC = () => {
    const [caseStudies, setCaseStudies] = useState<ICaseStudy[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCaseStudies = async () => {
            try {
                const response: ICaseStudy[] = await portfolioService.getCaseStudies();
                setCaseStudies(response);
            } catch (error) {
                console.error('Error fetching portfolios:', error);
            }
        };

        fetchCaseStudies();
    }, []);

    const handleEditCaseStudy = (id: string) => {
        navigate(`/administration/editcasestudy/${id}`);
    };

    const handleAddCaseStudy = () => {
        navigate('/administration/editcasestudy');
    };

    const handleDeleteCaseStudy = () => {
        navigate('/administration/editportfolio');
    };

    return (
        <Table
            title="Case Studies"
            data={caseStudies}
            handleAdd={handleAddCaseStudy}
            handleEdit={handleEditCaseStudy}
            handleDelete={handleDeleteCaseStudy}
      />
    );
};

export default CaseStudies;