import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import partnerService from '../../services/PartnerService';
import IPartner from '../../models/IPartner';
import Table from '../Table';

const Partners: React.FC = () => {
    const [partners, setPartners] = useState<IPartner[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response: IPartner[] = await partnerService.getPartners();
                setPartners(response);
            } catch (error) {
                console.error('Error fetching partners:', error);
            }
        };

        fetchBlogs();
    }, []);

    const handleEditPartner = (id: string) => {
        navigate(`/administration/partners/editPartner/${id}`);
    };

    const handleAddPartner = () => {
        navigate('/administration/partners/editPartner');
    };

    const handleDeletePartner = () => {
        navigate('/administration/partners/editPartner');
    };

    return (
        <Table
            title="Partners"
            data={partners}
            handleAdd={handleAddPartner}
            handleEdit={handleEditPartner}
            handleDelete={handleDeletePartner}
      />
    );
};

export default Partners;