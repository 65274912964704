import { Outlet } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import { Container } from "react-grid-system";
import styles from "./AdminLayout.module.scss";

export default function AdminLayout() {
    return (
        <div className={styles.wrapper}>
            <Sidebar />
            <main role="main" className={styles.mainContent}>
                <Container>
                    <Outlet /> {/* This will render child routes */}
                </Container>
            </main>
        </div>
    );
}