import IImageUploadRequest from "../interfaces/IImageUploadRequest";
import networkService from "./NetworkService";
class ImageService {

    /**
     * @description Uploads an image
     * @param {IImageUploadRequest} imageUploadRequest The image upload request data
     */
    async uploadImage(imageUploadRequest: IImageUploadRequest): Promise<string> {
        const result = await networkService.post<IImageUploadRequest, string>(`/images/url`, imageUploadRequest);

        if (result.isSuccessful) {
            return result.data as string;
        }

        throw result.data;
    }
}

const imageService = new ImageService();
export default imageService;