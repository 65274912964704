import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import testimonialService from '../../services/TestimonialService';
import ITestimonial from '../../models/ITestimonial';
import Table from '../Table';

const Testimonials: React.FC = () => {
    const [testimonials, setTestimonials] = useState<ITestimonial[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response: ITestimonial[] = await testimonialService.getTestimonials();
                setTestimonials(response);
            } catch (error) {
                console.error('Error fetching testimonials:', error);
            }
        };

        fetchTestimonials();
    }, []);

    const handleEditTestimonial = (id: string) => {
        navigate(`/administration/edittestimonial/${id}`);
    };

    const handleAddTestimonial = () => {
        navigate('/administration/edittestimonial');
    };

    const handleDeleteTestimonial = (id: string) => {
        // Implement delete logic here
        console.log(`Delete testimonial with id: ${id}`);
    };

    return (
        <Table
            title="Testimonials"
            data={testimonials}
            handleAdd={handleAddTestimonial}
            handleEdit={handleEditTestimonial}
            handleDelete={handleDeleteTestimonial}
      />
    );
};

export default Testimonials;