import { useEffect, useState } from 'react';
import { Card, CardHeader, CardPreview, Text, Image, Button, Link } from '@fluentui/react-components';
import blogService from '../services/BlogService';
import portfolioService from '../services/PortfolioService';
import testimonialService from '../services/TestimonialService';
import IPortfolio from '../models/ICaseStudy';
import IEntry from '../models/IBlogPost';
import ITestimonial from '../models/ITestimonial';

export default function Home() {
  const [caseStudies, setCaseStudies] = useState<IPortfolio[]>([]);
  const [blogPosts, setBlogPosts] = useState<IEntry[]>([]);
  const [testimonials, setTestimonials] = useState<ITestimonial[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogs = await blogService.getBlogs();
        const caseStudies = await portfolioService.getCaseStudies();
        const testimonials = await testimonialService.getTestimonials();

        setBlogPosts(blogs);
        setCaseStudies(caseStudies);
        setTestimonials(testimonials);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <section className="py-5 pt-md-15 pb-md-7" id="welcome">
        <div className="shape shape-blur-3 text-white">
          <video id="bgVideo" className="d-none d-md-block background-video" autoPlay loop muted>
            <source src="images/pages/home/top-banner-video-h264.mp4" type="video/mp4" />
          </video>
          <Image src="/images/pages/our-story/banner.webp" className="d-md-none background-video" alt="Our Story" />
        </div>
        <div className="text-center">
          <Text as="h1" className="display-3 fw-bold text-white">
            Redefine Success With Strategic IT Modernization
          </Text>
          <Text as="p" className="lead text-white mb-8">
            Digitally transform the way you work through Microsoft, Business Process Management Solutions, custom tailored software and more.
          </Text>
          <Button appearance="primary" href="/contact" className="btn btn-white shadow lift" as="a">
            Start your journey
          </Button>
        </div>
      </section>

      <section className="py-5" id="cards">
        <div>
          {caseStudies.map((caseStudy, index) => (
            <Card key={index}>
              <CardHeader>
                <Text as="h2" className="large">{caseStudy.title}</Text>
              </CardHeader>
                <Text>{caseStudy.subtitle}</Text>
            </Card>
          ))}
        </div>
      </section>

      <section className="py-5 py-md-8 bg-gradient-light-white border-bottom">
        <div className="text-center">
          <Text as="span" className="badge rounded-pill bg-primary mb-3">
            <span className="h6 text-uppercase">Our blog</span>
          </Text>
          <Text as="h2" className="xxLarge">Stay up to date with our news.</Text>
          <Text size={500} className="fs-lg text-muted mb-7 mb-md-9">
            We share common IT tips and guidelines, as well as latest news.
          </Text>
        </div>
        <div>
          {blogPosts.map((entry, index) => (
            <Card key={index}>
              <CardPreview>
                <Link href={`/blog/${entry.id}`}>
                  <Image src={entry.thumbnail.url} alt={entry.thumbnail.altText} />
                </Link>
              </CardPreview>
              <CardHeader>
                <Text size={500}>{entry.title.title}</Text>
              </CardHeader>
                <Text>{entry.metaDescription}</Text>
            </Card>
          ))}
        </div>
      </section>

      <section className="py-5" id="reviews">
        <div>
          {testimonials.map((testimonial, index) => (
            <Card key={index}>
              <CardHeader>
                <Text size={500}>{testimonial.client.name}</Text>
              </CardHeader>
                <Text>{testimonial.content}</Text>
            </Card>
          ))}
        </div>
      </section>
    </div>
  );
}