import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import blogService from '../../services/BlogService';
import IEntry from '../../models/IBlogPost';
import Table from '../Table';

const Blogs: React.FC = () => {
    const [blogs, setBlogs] = useState<IEntry[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response: IEntry[] = await blogService.getBlogs();
                setBlogs(response);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    const handleEditBlog = (id: string) => {
        navigate(`/administration/editblog/${id}`);
    };

    const handleAddBlog = () => {
        navigate('/administration/editblog');
    };

    const handleDeleteBlog = (id: string) => {
        // Implement delete logic here
        console.log(`Delete blog with id: ${id}`);
    };

    return (
        <Table
            title="Blogs"
            data={blogs}
            handleAdd={handleAddBlog}
            handleEdit={handleEditBlog}
            handleDelete={handleDeleteBlog}
        />
    );
};

export default Blogs;