import React from "react";
import { TabList, Tab, Link, Text, Image } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import styles from './Sidebar.module.scss';

export default function Sidebar() {
    const { t } = useTranslation();

    return (
        <div className={styles.sidebar}>
            <div className={styles.logoContainer}>
                <Image
                    alt={t("general.companyName")}
                    src="https://dolphinwebsiteuat.blob.core.windows.net/general-uploads/dolphin-solutions_logo_blue-black.png"
                    className={styles.logo}
                />
            </div>

            <TabList defaultSelectedValue="home" vertical>
                <Text size={400} weight="semibold" className={styles.linkHeader}>
                    {t("administration.navigation.blogManagement.title")}
                </Text>
                <Tab value="blogs" className={styles.tab}>
                    <Link href="/administration/blogs" className={styles.tab} appearance="subtle">
                        {t("administration.navigation.blogManagement.blogs")}
                    </Link>
                </Tab>
                <Tab value="blogcategories" className={styles.tab}>
                    <Link href="/administration/blogcategories" className={styles.tab} appearance="subtle">
                        {t("administration.navigation.blogManagement.categories")}
                    </Link>
                </Tab>
                <Text size={400} weight="semibold" className={styles.linkHeader}>
                    {t("administration.navigation.portfolioManagement.title")}
                </Text>
                <Tab value="portfolios" className={styles.tab}>
                    <Link href="/administration/portfolios" className={styles.tab} appearance="subtle">
                        {t("administration.navigation.portfolioManagement.portfolios")}
                    </Link>
                </Tab>
                <Tab value="portfoliocategories" className={styles.tab}>
                    <Link href="/administration/portfoliocategories" className={styles.tab} appearance="subtle">
                        {t("administration.navigation.portfolioManagement.categories")}
                    </Link>
                </Tab>
                <Text size={400} weight="semibold" className={styles.linkHeader}>
                    {t("administration.navigation.generalManagement.title")}
                </Text>
                <Tab value="awards" className={styles.tab}>
                    <Link href="/administration/awards" className={styles.tab} appearance="subtle">
                        {t("administration.navigation.generalManagement.awards")}
                    </Link>
                </Tab>
                <Tab value="testimonials" className={styles.tab}>
                    <Link href="/administration/testimonials" className={styles.tab} appearance="subtle">
                        {t("administration.navigation.generalManagement.testimonials")}
                    </Link>
                </Tab>
                <Tab value="partners" className={styles.tab}>
                    <Link href="/administration/partners" className={styles.tab} appearance="subtle">
                        {t("administration.navigation.generalManagement.partners")}
                    </Link>
                </Tab>
            </TabList>
        </div>
    );
}